define('ember-cli-gravatar/components/gravatar-image', ['exports', 'ember', 'md5'], function (exports, Ember, md5) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    tagName: 'img',
    attributeBindings: ['src', 'alt', 'title'],
    classNames: ['gravatar-image'],
    size: 250,
    email: '',
    title: '',
    default: '',

    src: Ember['default'].computed('email', 'size', 'default', function() {
      var email = this.get('email'),
          size = this.get('size'),
          def = this.get('default');

      return '//www.gravatar.com/avatar/' + md5['default'](email) + '?s=' + size + '&d=' + def;
    })
  });

});