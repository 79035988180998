define('liquid-fire/modals', ['exports', 'ember', 'liquid-fire/modal'], function (exports, Ember, Modal) {

  'use strict';

  exports['default'] = Ember['default'].Service.extend({
    routing: Ember['default'].inject.service('-routing'),

    setup: Ember['default'].on('init', function () {

      this.set('modalContexts', Ember['default'].A());
      this.set('modals', Ember['default'].A());

      var modalConfigs = this.container.lookup('router:main').router.modals;
      if (modalConfigs && modalConfigs.length > 0) {
        var self = this;
        modalConfigs.forEach(function (m) {
          self.registerModal(m);
        });
      }
    }),

    registerModal: function registerModal(config) {
      var ext = {
        modals: this,
        container: this.container
      };

      for (var param in config.options.withParams) {
        ext[param + "Observer"] = observerForParam(param);
      }

      this.get('modals').pushObject(Modal['default'].extend(ext).create(config));
    },

    activeRouteNames: Ember['default'].computed('routing.currentRouteName', function () {
      // We need this to force the right observers to all be in place
      // for invalidation, even though we aren't use it directly.
      this.get('routing.currentRouteName');

      var infos = this.container.lookup('router:main').router.currentHandlerInfos;
      if (infos) {
        return infos.map(function (h) {
          return h.name;
        });
      } else {
        return [];
      }
    })

  });

  function observerForParam(param) {
    return Ember['default'].observer('controller.' + param, function () {
      this.update();
    });
  }

});