define('ember-macro-helpers/get-value', ['exports', 'ember-metal/get', 'ember-macro-helpers/is-computed'], function (exports, get, isComputed) {

  'use strict';

  exports['default'] = function (context, key) {
    if (isComputed['default'](key)) {
      return key._getter.call(context);
    }

    if (typeof key !== 'string') {
      return key;
    }

    return get['default'](context, key);
  }

});