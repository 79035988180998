define('ember-moment/helpers/moment-from-now', ['exports', 'moment', 'ember-moment/utils/helper-compute', 'ember-moment/helpers/-base'], function (exports, moment, computeFn, BaseHelper) {

  'use strict';

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports['default'] = BaseHelper['default'].extend({
    globalAllowEmpty: false,

    compute: computeFn['default'](function (params, _ref) {
      var hideSuffix = _ref.hideSuffix;
      var locale = _ref.locale;
      var timeZone = _ref.timeZone;

      this._super.apply(this, arguments);

      return this.morphMoment(moment['default'].apply(undefined, _toConsumableArray(params)), { locale: locale, timeZone: timeZone }).fromNow(hideSuffix);
    })
  });

});