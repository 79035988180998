define('ember-power-select/templates/components/power-select/single', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 15,
                  "column": 4
                },
                "end": {
                  "line": 21,
                  "column": 4
                }
              },
              "moduleName": "modules/ember-power-select/templates/components/power-select/single.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","ember-power-select-search");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("input");
              dom.setAttribute(el2,"type","search");
              dom.setAttribute(el2,"autocomplete","off");
              dom.setAttribute(el2,"autocorrect","off");
              dom.setAttribute(el2,"autocapitalize","off");
              dom.setAttribute(el2,"spellcheck","false");
              dom.setAttribute(el2,"role","textbox");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1, 1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element0, 'oninput');
              morphs[1] = dom.createAttrMorph(element0, 'onkeydown');
              morphs[2] = dom.createAttrMorph(element0, 'placeholder');
              return morphs;
            },
            statements: [
              ["attribute","oninput",["subexpr","action",[["get","select.actions.search",["loc",[null,[18,59],[18,80]]]]],["value","target.value"],["loc",[null,[18,50],[18,103]]]]],
              ["attribute","onkeydown",["get","select.actions.handleKeydown",["loc",[null,[19,20],[19,48]]]]],
              ["attribute","placeholder",["get","searchPlaceholder",["loc",[null,[19,65],[19,82]]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 23,
                  "column": 6
                },
                "end": {
                  "line": 25,
                  "column": 6
                }
              },
              "moduleName": "modules/ember-power-select/templates/components/power-select/single.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              dom.setAttribute(el1,"class","ember-power-select-option");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
              return morphs;
            },
            statements: [
              ["content","loadingMessage",["loc",[null,[24,46],[24,64]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 27,
                    "column": 8
                  },
                  "end": {
                    "line": 31,
                    "column": 8
                  }
                },
                "moduleName": "modules/ember-power-select/templates/components/power-select/single.hbs"
              },
              arity: 2,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","yield",[["get","option",["loc",[null,[30,18],[30,24]]]],["get","term",["loc",[null,[30,25],[30,29]]]]],[],["loc",[null,[30,10],[30,31]]]]
              ],
              locals: ["option","term"],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 26,
                  "column": 6
                },
                "end": {
                  "line": 32,
                  "column": 6
                }
              },
              "moduleName": "modules/ember-power-select/templates/components/power-select/single.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","component",[["get","optionsComponent",["loc",[null,[27,21],[27,37]]]]],["options",["subexpr","readonly",[["get","results",["loc",[null,[27,56],[27,63]]]]],[],["loc",[null,[27,46],[27,64]]]],"highlighted",["subexpr","readonly",[["get","highlighted",["loc",[null,[27,87],[27,98]]]]],[],["loc",[null,[27,77],[27,99]]]],"selection",["subexpr","readonly",[["get","selection",["loc",[null,[28,30],[28,39]]]]],[],["loc",[null,[28,20],[28,40]]]],"optionsComponent",["subexpr","readonly",[["get","optionsComponent",["loc",[null,[28,68],[28,84]]]]],[],["loc",[null,[28,58],[28,85]]]],"searchText",["subexpr","readonly",[["get","searchText",["loc",[null,[28,107],[28,117]]]]],[],["loc",[null,[28,97],[28,118]]]],"select",["subexpr","readonly",[["get","select",["loc",[null,[29,27],[29,33]]]]],[],["loc",[null,[29,17],[29,34]]]],"extra",["subexpr","readonly",[["get","extra",["loc",[null,[29,51],[29,56]]]]],[],["loc",[null,[29,41],[29,57]]]]],0,null,["loc",[null,[27,8],[31,22]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child3 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 32,
                    "column": 6
                  },
                  "end": {
                    "line": 34,
                    "column": 6
                  }
                },
                "moduleName": "modules/ember-power-select/templates/components/power-select/single.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                dom.setAttribute(el1,"class","ember-power-select-option");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                return morphs;
              },
              statements: [
                ["content","searchMessage",["loc",[null,[33,46],[33,63]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.13",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 35,
                        "column": 8
                      },
                      "end": {
                        "line": 37,
                        "column": 8
                      }
                    },
                    "moduleName": "modules/ember-power-select/templates/components/power-select/single.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("          ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["inline","yield",[],["to","inverse"],["loc",[null,[36,10],[36,32]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.13",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 37,
                          "column": 8
                        },
                        "end": {
                          "line": 39,
                          "column": 8
                        }
                      },
                      "moduleName": "modules/ember-power-select/templates/components/power-select/single.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("          ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("li");
                      dom.setAttribute(el1,"class","ember-power-select-option");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n        ");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["content","noMatchesMessage",["loc",[null,[38,48],[38,68]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.13",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 37,
                        "column": 8
                      },
                      "end": {
                        "line": 39,
                        "column": 8
                      }
                    },
                    "moduleName": "modules/ember-power-select/templates/components/power-select/single.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","noMatchesMessage",["loc",[null,[37,18],[37,34]]]]],[],0,null,["loc",[null,[37,8],[39,8]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 34,
                      "column": 6
                    },
                    "end": {
                      "line": 40,
                      "column": 6
                    }
                  },
                  "moduleName": "modules/ember-power-select/templates/components/power-select/single.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("      ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","hasInverseBlock",["loc",[null,[35,14],[35,29]]]]],[],0,1,["loc",[null,[35,8],[39,15]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 34,
                    "column": 6
                  },
                  "end": {
                    "line": 40,
                    "column": 6
                  }
                },
                "moduleName": "modules/ember-power-select/templates/components/power-select/single.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","results.isFulfilled",["loc",[null,[34,16],[34,35]]]]],[],0,null,["loc",[null,[34,6],[40,6]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 32,
                  "column": 6
                },
                "end": {
                  "line": 40,
                  "column": 6
                }
              },
              "moduleName": "modules/ember-power-select/templates/components/power-select/single.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","mustShowSearchMessage",["loc",[null,[32,16],[32,37]]]]],[],0,1,["loc",[null,[32,6],[40,6]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 2
              },
              "end": {
                "line": 42,
                "column": 2
              }
            },
            "moduleName": "modules/ember-power-select/templates/components/power-select/single.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("ul");
            dom.setAttribute(el1,"class","ember-power-select-options");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [2]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(element1,1,1);
            morphs[2] = dom.createMorphAt(element1,2,2);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [
            ["block","if",[["get","searchEnabled",["loc",[null,[15,10],[15,23]]]]],[],0,null,["loc",[null,[15,4],[21,11]]]],
            ["block","if",[["get","showLoadingMessage",["loc",[null,[23,12],[23,30]]]]],[],1,null,["loc",[null,[23,6],[25,13]]]],
            ["block","if",[["get","resultsLength",["loc",[null,[26,12],[26,25]]]]],[],2,3,["loc",[null,[26,6],[40,13]]]]
          ],
          locals: ["select"],
          templates: [child0, child1, child2, child3]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 43,
              "column": 0
            }
          },
          "moduleName": "modules/ember-power-select/templates/components/power-select/single.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","with",[["subexpr","hash",[],["isOpen",["get","dropdown.isOpen",["loc",[null,[5,11],[5,26]]]],"actions",["subexpr","hash",[],["open",["subexpr","action",["open",["get","dropdown",["loc",[null,[7,26],[7,34]]]]],[],["loc",[null,[7,11],[7,35]]]],"close",["subexpr","action",["close",["get","dropdown",["loc",[null,[8,28],[8,36]]]]],[],["loc",[null,[8,12],[8,37]]]],"select",["subexpr","action",["select",["get","dropdown",["loc",[null,[9,30],[9,38]]]]],[],["loc",[null,[9,13],[9,39]]]],"highlight",["subexpr","action",["highlight",["get","dropdown",["loc",[null,[10,36],[10,44]]]]],[],["loc",[null,[10,16],[10,45]]]],"search",["subexpr","action",["search",["get","dropdown",["loc",[null,[11,30],[11,38]]]]],[],["loc",[null,[11,13],[11,39]]]],"clear",["subexpr","if",[["get","allowClear",["loc",[null,[12,16],[12,26]]]],["subexpr","action",["select",["get","dropdown",["loc",[null,[12,44],[12,52]]]],null],[],["loc",[null,[12,27],[12,58]]]]],[],["loc",[null,[12,12],[12,59]]]],"handleKeydown",["subexpr","action",["handleKeydown",["get","dropdown",["loc",[null,[13,44],[13,52]]]]],[],["loc",[null,[13,20],[13,53]]]]],["loc",[null,[6,12],[14,5]]]]],["loc",[null,[4,10],[14,6]]]]],[],0,null,["loc",[null,[4,2],[42,11]]]]
        ],
        locals: ["dropdown"],
        templates: [child0]
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 55,
                  "column": 4
                },
                "end": {
                  "line": 59,
                  "column": 4
                }
              },
              "moduleName": "modules/ember-power-select/templates/components/power-select/single.hbs"
            },
            arity: 2,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","yield",[["get","opt",["loc",[null,[58,14],[58,17]]]],["get","term",["loc",[null,[58,18],[58,22]]]]],[],["loc",[null,[58,6],[58,24]]]]
            ],
            locals: ["opt","term"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 44,
                "column": 2
              },
              "end": {
                "line": 60,
                "column": 2
              }
            },
            "moduleName": "modules/ember-power-select/templates/components/power-select/single.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","component",[["get","selectedComponent",["loc",[null,[55,17],[55,34]]]]],["options",["subexpr","readonly",[["get","results",["loc",[null,[55,53],[55,60]]]]],[],["loc",[null,[55,43],[55,61]]]],"selection",["subexpr","readonly",[["get","selection",["loc",[null,[55,82],[55,91]]]]],[],["loc",[null,[55,72],[55,92]]]],"searchText",["subexpr","readonly",[["get","searchText",["loc",[null,[55,114],[55,124]]]]],[],["loc",[null,[55,104],[55,125]]]],"placeholder",["subexpr","readonly",[["get","placeholder",["loc",[null,[56,28],[56,39]]]]],[],["loc",[null,[56,18],[56,40]]]],"disabled",["subexpr","readonly",[["get","disabled",["loc",[null,[56,60],[56,68]]]]],[],["loc",[null,[56,50],[56,69]]]],"highlighted",["subexpr","readonly",[["get","highlighted",["loc",[null,[56,92],[56,103]]]]],[],["loc",[null,[56,82],[56,104]]]],"select",["subexpr","readonly",[["get","select",["loc",[null,[57,23],[57,29]]]]],[],["loc",[null,[57,13],[57,30]]]],"extra",["subexpr","readonly",[["get","extra",["loc",[null,[57,47],[57,52]]]]],[],["loc",[null,[57,37],[57,53]]]]],0,null,["loc",[null,[55,4],[59,18]]]]
          ],
          locals: ["select"],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 43,
              "column": 0
            },
            "end": {
              "line": 61,
              "column": 0
            }
          },
          "moduleName": "modules/ember-power-select/templates/components/power-select/single.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","with",[["subexpr","hash",[],["isOpen",["get","registeredDropdown.isOpen",["loc",[null,[45,11],[45,36]]]],"actions",["subexpr","hash",[],["open",["subexpr","action",["open",["get","registeredDropdown",["loc",[null,[47,26],[47,44]]]]],[],["loc",[null,[47,11],[47,45]]]],"close",["subexpr","action",["close",["get","registeredDropdown",["loc",[null,[48,28],[48,46]]]]],[],["loc",[null,[48,12],[48,47]]]],"select",["subexpr","action",["select",["get","registeredDropdown",["loc",[null,[49,30],[49,48]]]]],[],["loc",[null,[49,13],[49,49]]]],"highlight",["subexpr","action",["highlight",["get","registeredDropdown",["loc",[null,[50,36],[50,54]]]]],[],["loc",[null,[50,16],[50,55]]]],"search",["subexpr","action",["search",["get","registeredDropdown",["loc",[null,[51,30],[51,48]]]]],[],["loc",[null,[51,13],[51,49]]]],"clear",["subexpr","if",[["get","allowClear",["loc",[null,[52,16],[52,26]]]],["subexpr","action",["select",["get","registeredDropdown",["loc",[null,[52,44],[52,62]]]],null],[],["loc",[null,[52,27],[52,68]]]]],[],["loc",[null,[52,12],[52,69]]]],"handleKeydown",["subexpr","action",["handleKeydown",["get","registeredDropdown",["loc",[null,[53,44],[53,62]]]]],[],["loc",[null,[53,20],[53,63]]]]],["loc",[null,[46,12],[54,5]]]]],["loc",[null,[44,10],[54,6]]]]],[],0,null,["loc",[null,[44,2],[60,11]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 62,
            "column": 0
          }
        },
        "moduleName": "modules/ember-power-select/templates/components/power-select/single.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["block","basic-dropdown",[],["class",["subexpr","readonly",[["get","concatenatedClasses",["loc",[null,[1,34],[1,53]]]]],[],["loc",[null,[1,24],[1,54]]]],"dir",["subexpr","@mut",[["get","dir",["loc",[null,[1,59],[1,62]]]]],[],[]],"tabindex",["subexpr","readonly",[["get","tabindex",["loc",[null,[1,82],[1,90]]]]],[],["loc",[null,[1,72],[1,91]]]],"renderInPlace",["subexpr","@mut",[["get","renderInPlace",["loc",[null,[1,106],[1,119]]]]],[],[]],"matchTriggerWidth",true,"disabled",["subexpr","@mut",[["get","disabled",["loc",[null,[2,11],[2,19]]]]],[],[]],"dropdownPosition",["subexpr","@mut",[["get","dropdownPosition",["loc",[null,[2,37],[2,53]]]]],[],[]],"triggerClass","ember-power-select-trigger","dropdownClass",["subexpr","@mut",[["get","concatenatedDropdownClasses",["loc",[null,[2,110],[2,137]]]]],[],[]],"onOpen",["subexpr","action",[["get","onOpen",["loc",[null,[3,17],[3,23]]]]],[],["loc",[null,[3,9],[3,24]]]],"onClose",["subexpr","action",[["get","onClose",["loc",[null,[3,41],[3,48]]]]],[],["loc",[null,[3,33],[3,49]]]],"onKeydown",["subexpr","action",["handleKeydown"],[],["loc",[null,[3,60],[3,84]]]],"registerActionsInParent",["subexpr","action",["registerDropdown"],[],["loc",[null,[3,109],[3,136]]]]],0,1,["loc",[null,[1,0],[61,19]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});