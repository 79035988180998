define('ember-power-select/components/power-select', ['exports', 'ember', 'ember-power-select/templates/components/power-select', 'ember-power-select/utils/group-utils'], function (exports, Ember, layout, group_utils) {

  'use strict';

  var defaultOptions = {
    tagName: '',
    layout: layout['default'],

    // Universal config
    disabled: false,
    placeholder: undefined,
    loadingMessage: "Loading options...",
    noMatchesMessage: "No results found",
    optionsComponent: 'power-select/options',
    dropdownPosition: 'auto',
    matcher: group_utils.defaultMatcher,
    searchField: null,
    search: null,
    closeOnSelect: true,
    dropdownClass: null,
    dir: null,

    // Select single config
    searchEnabled: true,
    searchMessage: "Type to search",
    searchPlaceholder: null,
    allowClear: false,

    // Select multiple config

    // Lifecycle hooks
    didInitAttrs: function didInitAttrs() {
      this._super.apply(this, arguments);
      Ember['default'].assert('{{power-select}} requires an `onchange` function', this.get('onchange') && typeof this.get('onchange') === 'function');
    },

    // CPs
    concreteComponentName: Ember['default'].computed('multiple', function () {
      return 'power-select/' + (this.get('multiple') ? 'multiple' : 'single');
    }),

    selectedComponent: Ember['default'].computed('multiple', function () {
      return 'power-select/' + (this.get('multiple') ? 'multiple' : 'single') + '/selected';
    })
  };

  exports['default'] = Ember['default'].Component.extend(defaultOptions);

  exports.defaultOptions = defaultOptions;

});