define('ember-macro-helpers/get-value-unsafe', ['exports', 'ember-macro-helpers/get-value'], function (exports, getValue) {

  'use strict';

  exports['default'] = function (context, key) {
    var value = getValue['default'](context, key);
    if (value) {
      return value;
    }

    return key;
  }

});