define('ember-hash-helper-polyfill/helpers/hash', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.hash = hash;

  function hash(_, obj) {
    return obj;
  }

  exports['default'] = Ember['default'].Helper.helper(hash);

});