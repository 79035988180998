define('ember-moment/computeds/to-now', ['exports', 'moment', 'ember-moment/computeds/-base'], function (exports, moment, computedFactory) {

  'use strict';

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports['default'] = computedFactory['default'](function toNowComputed(params) {
    var maybeHidePrefix = undefined;

    if (params.length > 1) {
      maybeHidePrefix = params.pop();
    }

    return moment['default'].apply(undefined, _toConsumableArray(params)).toNow(maybeHidePrefix);
  });

});