define('ember-power-select/components/power-select/base', ['exports', 'ember', 'ember-power-select/utils/group-utils'], function (exports, Ember, group_utils) {

  'use strict';

  var RSVP = Ember['default'].RSVP;
  var computed = Ember['default'].computed;
  var run = Ember['default'].run;
  var get = Ember['default'].get;
  var isBlank = Ember['default'].isBlank;

  var Promise = RSVP.Promise;
  var PromiseArray = Ember['default'].ArrayProxy.extend(Ember['default'].PromiseProxyMixin);

  exports['default'] = Ember['default'].Component.extend({
    tagName: '',
    searchText: '',
    searchReturnedUndefined: false,
    activeSearch: null,
    attributeBindings: ['dir'],

    // CPs
    showLoadingMessage: computed.and('loadingMessage', 'results.isPending'),

    concatenatedDropdownClasses: computed('class', function () {
      var classes = Ember['default'].A(['ember-power-select-dropdown']);
      if (this.get('dropdownClass')) {
        classes.push(this.get('dropdownClass'));
      }
      if (this.get('class')) {
        classes.push(this.get('class') + '-dropdown');
      }
      return classes.join(' ');
    }),

    mustShowSearchMessage: computed('searchText', 'search', 'searchMessage', function () {
      return this.get('searchText.length') === 0 && !!this.get('search') && !!this.get('searchMessage');
    }),

    results: computed('options.[]', 'searchText', function () {
      var _this = this;

      var _getProperties = this.getProperties('options', 'searchText', 'previousResults');

      var options = _getProperties.options;
      var searchText = _getProperties.searchText;
      var _getProperties$previousResults = _getProperties.previousResults;
      var previousResults = _getProperties$previousResults === undefined ? Ember['default'].A() : _getProperties$previousResults;
      // jshint ignore:line
      var promise = undefined;
      if (isBlank(searchText) || this.searchReturnedUndefined) {
        promise = Promise.resolve(options).then(function (opts) {
          return Ember['default'].A(opts);
        });
      } else if (this.get('search')) {
        var result = this.get('search')(searchText);
        if (!result) {
          promise = Promise.resolve(previousResults);
          this.searchReturnedUndefined = true;
        } else {
          (function () {
            _this.searchReturnedValue = false;
            var search = _this.activeSearch = Promise.resolve(result);
            promise = search.then(function (opts) {
              return search !== _this.activeSearch ? previousResults : Ember['default'].A(opts);
            });
          })();
        }
      } else {
        promise = Promise.resolve(options).then(function (opts) {
          return _this.filter(Ember['default'].A(opts), _this.get('searchText'));
        });
      }
      promise.then(function (opts) {
        return _this.set('previousResults', opts);
      });
      return PromiseArray.create({ promise: promise, content: previousResults });
    }),

    highlighted: computed('results.[]', 'selected', {
      get: function get() {
        return this.defaultHighlighted();
      },
      set: function set(_, v) {
        return v;
      }
    }),

    resultsLength: computed('results.[]', function () {
      return group_utils.countOptions(this.get('results'));
    }),

    // Actions
    actions: {
      open: function open(dropdown, e) {
        dropdown.actions.open(e);
      },

      close: function close(dropdown, e) {
        dropdown.actions.close(e);
      },

      highlight: function highlight(dropdown, option) {
        if (option && get(option, 'disabled')) {
          return;
        }
        this.set('highlighted', option);
      },

      search: function search(dropdown, term /*, e */) {
        this.set('searchText', term);
      },

      handleKeydown: function handleKeydown(dropdown, e) {
        if (e.defaultPrevented) {
          return;
        }
        if (e.keyCode === 38 || e.keyCode === 40) {
          // Up & Down
          if (dropdown.isOpen) {
            this.handleVerticalArrowKey(e);
          } else {
            dropdown.actions.open(e);
          }
        } else if (e.keyCode === 9) {
          // Tab
          dropdown.actions.close(e);
        } else if (e.keyCode === 27) {
          // ESC
          dropdown.actions.close(e);
        }
      },

      // It is not evident what is going on here, so I'll explain why.
      //
      // As of this writting, Ember doesn allow to yield data to the "inverse" block.
      // Because of that, elements of this component rendered in the trigger can't receive the
      // yielded object contaning the public API of the ember-basic-dropdown, with actions for open,
      // close and toggle.
      //
      // The only possible workaround for this is to on initialization inject a similar object
      // to the one yielded and store it to make it available in the entire component.
      //
      // This this limitation on ember should be fixed soon, this is temporary. Because of that this
      // object will be passed to the action from the inverse block like if it was yielded.
      //
      registerDropdown: function registerDropdown(dropdown) {
        this.set('registeredDropdown', dropdown);
      }
    },

    // Methods
    onOpen: function onOpen(e) {
      run.scheduleOnce('afterRender', this, this.focusSearch, e);
      run.scheduleOnce('afterRender', this, this.scrollIfHighlightedIsOutOfViewport);
    },

    onClose: function onClose() {
      this.set('searchText', '');
    },

    handleVerticalArrowKey: function handleVerticalArrowKey(e) {
      e.preventDefault();
      var newHighlighted = this.advanceSelectableOption(this.get('highlighted'), e.keyCode === 40 ? 1 : -1);
      this.set('highlighted', newHighlighted);
      run.scheduleOnce('afterRender', this, this.scrollIfHighlightedIsOutOfViewport);
    },

    scrollIfHighlightedIsOutOfViewport: function scrollIfHighlightedIsOutOfViewport() {
      var optionsList = document.querySelector('.ember-power-select-options');
      if (!optionsList) {
        return;
      }
      var highlightedOption = optionsList.querySelector('.ember-power-select-option--highlighted');
      if (!highlightedOption) {
        return;
      }
      var optionTopScroll = highlightedOption.offsetTop - optionsList.offsetTop;
      var optionBottomScroll = optionTopScroll + highlightedOption.offsetHeight;
      if (optionBottomScroll > optionsList.offsetHeight + optionsList.scrollTop) {
        optionsList.scrollTop = optionBottomScroll - optionsList.offsetHeight;
      } else if (optionTopScroll < optionsList.scrollTop) {
        optionsList.scrollTop = optionTopScroll;
      }
    },

    indexOfOption: function indexOfOption(option) {
      return group_utils.indexOfOption(this.get('results'), option);
    },

    optionAtIndex: function optionAtIndex(index) {
      return group_utils.optionAtIndex(this.get('results'), index);
    },

    advanceSelectableOption: function advanceSelectableOption(currentHighlight, step) {
      var resultsLength = this.get('resultsLength');
      var startIndex = Math.min(Math.max(this.indexOfOption(currentHighlight) + step, 0), resultsLength - 1);
      var nextOption = this.optionAtIndex(startIndex);
      while (nextOption && get(nextOption, 'disabled')) {
        nextOption = this.optionAtIndex(startIndex += step);
      }
      return nextOption;
    },

    filter: function filter(options, searchText) {
      var _this2 = this;

      var matcher = undefined;
      if (this.get('searchField')) {
        matcher = function (option, text) {
          return _this2.matcher(get(option, _this2.get('searchField')), text);
        };
      } else {
        matcher = function (option, text) {
          return _this2.matcher(option, text);
        };
      }
      return group_utils.filterOptions(options || [], searchText, matcher);
    }
  });

});