define('liquid-fire/constraint', ['exports', 'ember', 'liquid-fire/constrainables'], function (exports, Ember, constrainables) {

  'use strict';

  exports.constraintKeys = constraintKeys;

  var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

  var Constraint = (function () {
    function Constraint(target, matcher) {
      _classCallCheck(this, Constraint);

      // targets are the properties of a transition that we can
      // constrain
      this.target = target;
      if (arguments.length === 1) {
        return;
      }
      if (matcher instanceof RegExp) {
        this.predicate = function (value) {
          return matcher.test(value);
        };
      } else if (typeof matcher === 'function') {
        this.predicate = matcher;
      } else if (typeof matcher === 'boolean') {
        this.predicate = function (value) {
          return matcher ? value : !value;
        };
      } else {
        this.keys = constraintKeys(matcher);
      }
    }

    _createClass(Constraint, [{
      key: "invert",
      value: function invert() {
        if (!constrainables['default'][this.target].reversesTo) {
          return this;
        }
        var inverse = new this.constructor(constrainables['default'][this.target].reversesTo);
        inverse.predicate = this.predicate;
        inverse.keys = this.keys;
        return inverse;
      }
    }]);

    return Constraint;
  })();

  exports['default'] = Constraint;

  var EMPTY = '__liquid_fire_EMPTY__';
  var ANY = '__liquid_fire_ANY__';

  function constraintKeys(matcher) {
    if (typeof matcher === 'undefined' || matcher === null) {
      matcher = [EMPTY];
    } else if (!Ember['default'].isArray(matcher)) {
      matcher = [matcher];
    }
    return Ember['default'].A(matcher).map(function (elt) {
      if (typeof elt === 'string') {
        return elt;
      } else {
        return Ember['default'].guidFor(elt);
      }
    });
  }

  exports.EMPTY = EMPTY;
  exports.ANY = ANY;

});