define('liquid-fire/version-warnings', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  function emberVersion() {
    var m = /^(\d+)\.(\d+)/.exec(Ember['default'].VERSION);
    if (!m) {
      return [0, 0];
    }
    return [parseInt(m[1]), parseInt(m[2])];
  }

  exports['default'] = function (args) {

    if (Ember['default'].compare(args.minEmberVersion, emberVersion()) === 1) {
      Ember['default'].warn("This version of liquid fire requires Ember " + args.minEmberVersion.join('.') + " or newer");
    }

    if (!Ember['default'].$.Velocity) {
      Ember['default'].warn("Velocity.js is missing");
    } else {
      var version = Ember['default'].$.Velocity.version;
      if (Ember['default'].compare(args.minVelocityVersion, [version.major, version.minor, version.patch]) === 1) {
        Ember['default'].warn("You should probably upgrade Velocity.js, recommended minimum is " + args.minVelocityVersion.join('.'));
      }
    }
  }

});