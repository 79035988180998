define('ember-macro-helpers/computed', ['exports', 'ember-macro-helpers/-build-computed', 'ember-macro-helpers/collapse-keys', 'ember-macro-helpers/get-value', 'ember-macro-helpers/flatten-keys'], function (exports, buildComputed, collapseKeys, getValue, flattenKeys) {

  'use strict';

  exports['default'] = function () {
    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return buildComputed['default'](args, collapseKeys['default'], getValue['default'], flattenKeys['default']);
  }

});