define('ember-basic-dropdown/templates/components/basic-dropdown', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 2
              },
              "end": {
                "line": 9,
                "column": 2
              }
            },
            "moduleName": "modules/ember-basic-dropdown/templates/components/basic-dropdown.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element0, 'class');
            morphs[1] = dom.createAttrMorph(element0, 'dir');
            morphs[2] = dom.createMorphAt(element0,1,1);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["ember-basic-dropdown-content ",["get","dropdownClass",["loc",[null,[6,47],[6,60]]]]," ",["get","_dropdownPositionClass",["loc",[null,[6,65],[6,87]]]]]]],
            ["attribute","dir",["get","dir",["loc",[null,[6,97],[6,100]]]]],
            ["inline","yield",[["get","publicAPI",["loc",[null,[7,14],[7,23]]]]],[],["loc",[null,[7,6],[7,25]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 0
            },
            "end": {
              "line": 10,
              "column": 0
            }
          },
          "moduleName": "modules/ember-basic-dropdown/templates/components/basic-dropdown.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","ember-wormhole",[],["to",["subexpr","@mut",[["get","_wormholeDestination",["loc",[null,[5,23],[5,43]]]]],[],[]],"renderInPlace",["subexpr","@mut",[["get","renderInPlace",["loc",[null,[5,58],[5,71]]]]],[],[]]],0,null,["loc",[null,[5,2],[9,21]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 7
          }
        },
        "moduleName": "modules/ember-basic-dropdown/templates/components/basic-dropdown.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var morphs = new Array(7);
        morphs[0] = dom.createAttrMorph(element1, 'class');
        morphs[1] = dom.createAttrMorph(element1, 'tabindex');
        morphs[2] = dom.createAttrMorph(element1, 'onkeydown');
        morphs[3] = dom.createAttrMorph(element1, 'onclick');
        morphs[4] = dom.createAttrMorph(element1, 'onfocus');
        morphs[5] = dom.createMorphAt(element1,1,1);
        morphs[6] = dom.createMorphAt(fragment,2,2,contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["ember-basic-dropdown-trigger ",["get","triggerClass",["loc",[null,[1,43],[1,55]]]]]]],
        ["attribute","tabindex",["get","tabIndex",["loc",[null,[1,70],[1,78]]]]],
        ["attribute","onkeydown",["subexpr","action",["keydown"],[],["loc",[null,[1,91],[1,111]]]]],
        ["attribute","onclick",["subexpr","action",["toggle"],[],["loc",[null,[1,120],[1,139]]]]],
        ["attribute","onfocus",["subexpr","action",["focusTrigger"],[],["loc",[null,[1,148],[1,173]]]]],
        ["inline","yield",[],["to","inverse"],["loc",[null,[2,2],[2,24]]]],
        ["block","if",[["get","opened",["loc",[null,[4,6],[4,12]]]]],[],0,null,["loc",[null,[4,0],[10,7]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});