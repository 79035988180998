define('ember-power-select/components/power-select/single', ['exports', 'ember', 'ember-power-select/components/power-select/base', 'ember-power-select/templates/components/power-select/single'], function (exports, Ember, PowerSelectBaseComponent, layout) {

  'use strict';

  var computed = Ember['default'].computed;

  exports['default'] = PowerSelectBaseComponent['default'].extend({
    layout: layout['default'],

    // CPs
    selection: computed('selected', {
      get: function get() {
        return this.get('selected');
      },
      set: function set(_, v) {
        return v;
      }
    }),

    concatenatedClasses: computed('class', function () {
      var classes = ['ember-power-select'];
      if (this.get('class')) {
        classes.push(this.get('class'));
      }
      return classes.join(' ');
    }),

    // Actions
    actions: {
      select: function select(dropdown, option, e) {
        e.preventDefault();
        e.stopPropagation();
        if (this.get('closeOnSelect')) {
          dropdown.actions.close(e);
        }
        if (this.get('selection') !== option) {
          this.get('onchange')(option, dropdown);
        }
      },

      handleKeydown: function handleKeydown(dropdown, e) {
        var onkeydown = this.get('onkeydown');
        if (onkeydown) {
          onkeydown(dropdown, e);
        }
        if (e.defaultPrevented) {
          return;
        }
        if (e.keyCode === 13 && dropdown.isOpen) {
          // Enter
          this.send('select', dropdown, this.get('highlighted'), e);
        } else {
          this._super.apply(this, arguments);
        }
      }
    },

    // Methods

    defaultHighlighted: function defaultHighlighted() {
      return this.get('selection') || this.optionAtIndex(0);
    },

    focusSearch: function focusSearch() {
      Ember['default'].$('.ember-power-select-search input').focus();
    }
  });

});