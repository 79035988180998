define('ember-power-select/components/power-select/multiple', ['exports', 'ember', 'ember-power-select/components/power-select/base', 'ember-power-select/templates/components/power-select/multiple'], function (exports, Ember, PowerSelectBaseComponent, layout) {

  'use strict';

  var computed = Ember['default'].computed;
  var get = Ember['default'].get;

  exports['default'] = PowerSelectBaseComponent['default'].extend({
    layout: layout['default'],

    // CPs
    selection: computed('selected', {
      get: function get() {
        return Ember['default'].A(this.get('selected'));
      },
      set: function set(_, v) {
        return v;
      }
    }),

    triggerUniqueClass: computed('elementId', function () {
      return 'ember-power-select-trigger-' + this.elementId;
    }),

    triggerClass: computed('triggerUniqueClass', function () {
      return 'ember-power-select-trigger ' + this.get('triggerUniqueClass');
    }),

    concatenatedClasses: computed('class', function () {
      var classes = ['ember-power-select', 'multiple'];
      if (this.get('class')) {
        classes.push(this.get('class'));
      }
      return classes.join(' ');
    }),

    // Actions
    actions: {
      select: function select(dropdown, option, e) {
        e.preventDefault();
        e.stopPropagation();
        var newSelection = this.cloneSelection();
        if (newSelection.indexOf(option) > -1) {
          newSelection.removeObject(option);
        } else {
          newSelection.addObject(option);
        }
        if (this.get('closeOnSelect')) {
          dropdown.actions.close(e);
        }
        this.get('onchange')(newSelection, dropdown);
      },

      removeOption: function removeOption(dropdown, option, e) {
        e.stopPropagation();
        this.removeOption(dropdown, option);
      },

      handleKeydown: function handleKeydown(dropdown, e) {
        var onkeydown = this.get('onkeydown');
        if (onkeydown) {
          onkeydown(dropdown, e);
        }
        if (e.defaultPrevented) {
          return;
        }
        if (e.keyCode === 8) {
          // BACKSPACE
          this.removeLastOptionIfSearchIsEmpty(dropdown);
          dropdown.actions.open(e);
        } else if (e.keyCode === 13) {
          e.stopPropagation();
          if (dropdown.isOpen) {
            var highlighted = this.get('highlighted');
            if (highlighted && (this.get('selected') || []).indexOf(highlighted) === -1) {
              this.send('select', dropdown, highlighted, e);
            } else {
              dropdown.actions.close(e);
            }
          } else {
            dropdown.actions.open(e);
          }
        } else {
          this._super.apply(this, arguments);
        }
      }
    },

    // Methods
    defaultHighlighted: function defaultHighlighted() {
      return this.optionAtIndex(0);
    },

    removeLastOptionIfSearchIsEmpty: function removeLastOptionIfSearchIsEmpty(dropdown) {
      if (this.get('searchText.length') !== 0) {
        return;
      }
      var lastSelection = this.get('selection.lastObject');
      if (!lastSelection) {
        return;
      }
      var lastText = typeof lastSelection === 'string' ? lastSelection : get(lastSelection, this.get('searchField'));
      this.removeOption(dropdown, lastSelection);
      this.set('searchText', lastText);
    },

    removeOption: function removeOption(dropdown, option) {
      var newSelection = this.cloneSelection();
      newSelection.removeObject(option);
      this._resultsDirty = true;
      this.get('onchange')(newSelection, dropdown);
    },

    focusSearch: function focusSearch() {
      var el = Ember['default'].$('.' + this.get('triggerUniqueClass'))[0];
      if (el) {
        el.querySelector('.ember-power-select-trigger-multiple-input').focus();
      }
    },

    cloneSelection: function cloneSelection() {
      return Ember['default'].A((this.get('selection') || []).slice(0));
    }
  });

});