define('ember-power-select/templates/components/power-select/multiple/selected', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 4
              },
              "end": {
                "line": 5,
                "column": 4
              }
            },
            "moduleName": "modules/ember-power-select/templates/components/power-select/multiple/selected.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"aria-label","remove element");
            dom.setAttribute(el1,"class","ember-power-select-multiple-remove-btn");
            var el2 = dom.createTextNode("×");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element0, 'onclick');
            return morphs;
          },
          statements: [
            ["attribute","onclick",["subexpr","action",[["get","select.actions.removeOption",["loc",[null,[4,104],[4,131]]]],["get","opt",["loc",[null,[4,132],[4,135]]]]],[],["loc",[null,[4,95],[4,137]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 8,
              "column": 0
            }
          },
          "moduleName": "modules/ember-power-select/templates/components/power-select/multiple/selected.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","ember-power-select-multiple-option");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element1,1,1);
          morphs[1] = dom.createMorphAt(element1,3,3);
          return morphs;
        },
        statements: [
          ["block","unless",[["get","disabled",["loc",[null,[3,14],[3,22]]]]],[],0,null,["loc",[null,[3,4],[5,15]]]],
          ["inline","yield",[["get","opt",["loc",[null,[6,12],[6,15]]]],["get","searchText",["loc",[null,[6,16],[6,26]]]]],[],["loc",[null,[6,4],[6,28]]]]
        ],
        locals: ["opt"],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 13,
            "column": 52
          }
        },
        "moduleName": "modules/ember-power-select/templates/components/power-select/multiple/selected.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("input");
        dom.setAttribute(el1,"type","search");
        dom.setAttribute(el1,"class","ember-power-select-trigger-multiple-input");
        dom.setAttribute(el1,"tabindex","0");
        dom.setAttribute(el1,"autocomplete","off");
        dom.setAttribute(el1,"autocorrect","off");
        dom.setAttribute(el1,"autocapitalize","off");
        dom.setAttribute(el1,"spellcheck","false");
        dom.setAttribute(el1,"role","textbox");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("span");
        dom.setAttribute(el1,"class","ember-power-select-status-icon");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [1]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        morphs[1] = dom.createAttrMorph(element2, 'style');
        morphs[2] = dom.createAttrMorph(element2, 'placeholder');
        morphs[3] = dom.createAttrMorph(element2, 'value');
        morphs[4] = dom.createAttrMorph(element2, 'disabled');
        morphs[5] = dom.createAttrMorph(element2, 'oninput');
        morphs[6] = dom.createAttrMorph(element2, 'onkeydown');
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [
        ["block","each",[["get","selection",["loc",[null,[1,8],[1,17]]]]],[],0,null,["loc",[null,[1,0],[8,9]]]],
        ["attribute","style",["get","triggerMultipleInputStyle",["loc",[null,[10,83],[10,108]]]]],
        ["attribute","placeholder",["get","maybePlaceholder",["loc",[null,[11,16],[11,32]]]]],
        ["attribute","value",["get","searchText",["loc",[null,[11,43],[11,53]]]]],
        ["attribute","disabled",["get","disabled",["loc",[null,[11,67],[11,75]]]]],
        ["attribute","oninput",["subexpr","action",["search"],["value","target.value"],["loc",[null,[12,10],[12,50]]]]],
        ["attribute","onkeydown",["get","select.actions.handleKeydown",["loc",[null,[12,63],[12,91]]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});