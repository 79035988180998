define('ember-macro-helpers/is-computed', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ComputedProperty = Ember['default'].ComputedProperty;

  exports['default'] = function (key) {
    return key instanceof ComputedProperty;
  }

});