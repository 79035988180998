define('ember-basic-dropdown/components/basic-dropdown', ['exports', 'ember', 'ember-basic-dropdown/templates/components/basic-dropdown', 'ember-getowner-polyfill'], function (exports, Ember, layout, getOwner) {

  'use strict';

  var Component = Ember['default'].Component;
  var run = Ember['default'].run;
  var computed = Ember['default'].computed;

  var MutObserver = window.MutationObserver || window.WebKitMutationObserver;

  exports['default'] = Component.extend({
    layout: layout['default'],
    disabled: false,
    renderInPlace: false,
    dropdownPosition: 'auto', // auto | above | below
    classNames: ['ember-basic-dropdown'],
    attributeBindings: ['dir'],
    classNameBindings: ['publicAPI.isOpen:ember-basic-dropdown--opened', 'disabled:ember-basic-dropdown--disabled', 'renderInPlace:ember-basic-dropdown--in-place', '_dropdownPositionClass'],
    _wormholeDestination: Ember['default'].testing ? 'ember-testing' : 'ember-basic-dropdown-wormhole',

    // Lifecycle hooks
    init: function init() {
      this._super.apply(this, arguments);
      var rootSelector = Ember['default'].testing ? '#ember-testing' : getOwner['default'](this).lookup('application:main').rootElement;
      this.appRoot = document.querySelector(rootSelector);
      this.handleRootClick = this.handleRootClick.bind(this);
      this.handleRepositioningEvent = this.handleRepositioningEvent.bind(this);
      this.repositionDropdown = this.repositionDropdown.bind(this);
    },

    didInitAttrs: function didInitAttrs() {
      this._super.apply(this, arguments);
      var registerActionsInParent = this.get('registerActionsInParent');
      if (registerActionsInParent) {
        registerActionsInParent(this.get('publicAPI'));
      }
    },

    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.removeGlobalEvents();
    },

    // CPs
    tabIndex: computed('disabled', function () {
      return !this.get('disabled') ? this.get('tabindex') || '0' : "-1";
    }),

    publicAPI: computed(function () {
      return {
        isOpen: false,
        actions: {
          open: this.open.bind(this),
          close: this.close.bind(this),
          toggle: this.toggle.bind(this)
        }
      };
    }),

    opened: computed('publicAPI.isOpen', {
      get: function get() {
        return this.get('publicAPI.isOpen');
      },
      set: function set(_, newOpened) {
        var oldOpened = this.get('publicAPI.isOpen');
        if (!oldOpened && newOpened) {
          this.open();
        } else if (oldOpened && !newOpened) {
          this.close();
        }
        return this.get('publicAPI.isOpen');
      }
    }),

    // Actions
    actions: {
      toggle: function toggle(e) {
        this.toggle(e);
      },

      keydown: function keydown(e) {
        this.handleKeydown(e);
      },

      focusTrigger: function focusTrigger(e) {
        var onFocus = this.get('onFocus');
        if (onFocus) {
          onFocus(this.get('publicAPI'), e);
        }
      }
    },

    // Methods
    toggle: function toggle(e) {
      if (this.get('publicAPI.isOpen')) {
        this.close(e);
      } else {
        this.open(e);
      }
    },

    open: function open(e) {
      if (this.get('disabled')) {
        return;
      }
      this.set('publicAPI.isOpen', true);
      run.scheduleOnce('afterRender', this, this.addGlobalEvents);
      run.scheduleOnce('afterRender', this, this.repositionDropdown);
      var onOpen = this.get('onOpen');
      if (onOpen) {
        onOpen(this.get('publicAPI'), e);
      }
    },

    close: function close(e, skipFocus) {
      this.set('publicAPI.isOpen', false);
      this.set('_dropdownPositionClass', null);
      this.removeGlobalEvents();
      var onClose = this.get('onClose');
      if (onClose) {
        onClose(this.get('publicAPI'), e);
      }
      if (skipFocus) {
        return;
      }
      var trigger = this.element.querySelector('.ember-basic-dropdown-trigger');
      if (trigger.tabIndex > -1) {
        trigger.focus();
      }
    },

    handleKeydown: function handleKeydown(e) {
      if (this.get('disabled')) {
        return;
      }
      var onKeydown = this.get('onKeydown');
      if (onKeydown) {
        onKeydown(this.get('publicAPI'), e);
      }
      if (e.defaultPrevented) {
        return;
      }
      if (e.keyCode === 13) {
        // Enter
        this.toggle(e);
      } else if (e.keyCode === 27) {
        this.close(e);
      }
    },

    repositionDropdown: function repositionDropdown() {
      run(this, this._runloopAwareRepositionDropdown);
    },

    handleRootClick: function handleRootClick(e) {
      if (!this.element.contains(e.target) && !this.appRoot.querySelector('.ember-basic-dropdown-content').contains(e.target)) {
        this.close(e, true);
      }
    },

    handleRepositioningEvent: function handleRepositioningEvent() /* e */{
      run.throttle(this, 'repositionDropdown', 60, true);
    },

    addGlobalEvents: function addGlobalEvents() {
      var _this = this;

      this.appRoot.addEventListener('click', this.handleRootClick, true);
      window.addEventListener('scroll', this.handleRepositioningEvent);
      window.addEventListener('resize', this.handleRepositioningEvent);
      window.addEventListener('orientationchange', this.handleRepositioningEvent);
      if (MutObserver) {
        this.mutationObserver = new MutObserver(function (mutations) {
          if (mutations[0].addedNodes.length || mutations[0].removedNodes.length) {
            _this.repositionDropdown();
          }
        });
        run.schedule('afterRender', this, function () {
          var dropdown = this.appRoot.querySelector('.ember-basic-dropdown-content');
          this.mutationObserver.observe(dropdown, { childList: true, subtree: true });
        });
      } else {
        run.schedule('afterRender', this, function () {
          var dropdown = this.appRoot.querySelector('.ember-basic-dropdown-content');
          dropdown.addEventListener('DOMNodeInserted', this.repositionDropdown, false);
          dropdown.addEventListener('DOMNodeRemoved', this.repositionDropdown, false);
        });
      }
    },

    removeGlobalEvents: function removeGlobalEvents() {
      this.appRoot.removeEventListener('click', this.handleRootClick, true);
      window.removeEventListener('scroll', this.handleRepositioningEvent);
      window.removeEventListener('resize', this.handleRepositioningEvent);
      window.removeEventListener('orientationchange', this.handleRepositioningEvent);
      if (MutObserver) {
        if (this.mutationObserver) {
          this.mutationObserver.disconnect();
          this.mutationObserver = null;
        }
      } else {
        var dropdown = this.appRoot.querySelector('.ember-basic-dropdown-content');
        dropdown.removeEventListener('DOMNodeInserted', this.repositionDropdown);
        dropdown.removeEventListener('DOMNodeRemoved', this.repositionDropdown);
      }
    },

    _runloopAwareRepositionDropdown: function _runloopAwareRepositionDropdown() {
      if (this.get('renderInPlace') || !this.get('publicAPI.isOpen')) {
        return;
      }
      var dropdownPositionStrategy = this.get('dropdownPosition');
      var dropdown = this.appRoot.querySelector('.ember-basic-dropdown-content');
      var trigger = this.element.querySelector('.ember-basic-dropdown-trigger');

      var _trigger$getBoundingClientRect = trigger.getBoundingClientRect();

      var left = _trigger$getBoundingClientRect.left;
      var topWithoutScroll = _trigger$getBoundingClientRect.top;
      var width = _trigger$getBoundingClientRect.width;
      var height = _trigger$getBoundingClientRect.height;

      var viewportTop = Ember['default'].$(window).scrollTop();
      var top = topWithoutScroll + viewportTop;
      if (this.get('matchTriggerWidth')) {
        dropdown.style.width = width + 'px';
      }
      if (dropdownPositionStrategy === 'above') {
        top = top - dropdown.getBoundingClientRect().height;
        this.set('_dropdownPositionClass', 'ember-basic-dropdown--above');
      } else if (dropdownPositionStrategy === 'below') {
        top = top + height;
        this.set('_dropdownPositionClass', 'ember-basic-dropdown--below');
      } else {
        // auto
        var viewportBottom = window.scrollY + window.innerHeight;
        var dropdownHeight = dropdown.getBoundingClientRect().height;
        var enoughRoomBelow = top + height + dropdownHeight < viewportBottom;
        var enoughRoomAbove = topWithoutScroll > dropdownHeight;
        var positionClass = this.get('_dropdownPositionClass');
        if (positionClass === 'ember-basic-dropdown--below' && !enoughRoomBelow && enoughRoomAbove) {
          this.set('_dropdownPositionClass', 'ember-basic-dropdown--above');
        } else if (positionClass === 'ember-basic-dropdown--above' && !enoughRoomAbove && enoughRoomBelow) {
          this.set('_dropdownPositionClass', 'ember-basic-dropdown--below');
        } else if (!positionClass) {
          this.set('_dropdownPositionClass', enoughRoomBelow ? 'ember-basic-dropdown--below' : 'ember-basic-dropdown--above');
        }
        positionClass = this.get('_dropdownPositionClass'); // It might have changed
        top = top + (positionClass === 'ember-basic-dropdown--below' ? height : -dropdownHeight);
      }
      dropdown.style.top = top + 'px';
      dropdown.style.left = left + 'px';
    }
  });

});