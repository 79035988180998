define('ember-power-select/components/power-select/multiple/selected', ['exports', 'ember', 'ember-power-select/templates/components/power-select/multiple/selected'], function (exports, Ember, layout) {

  'use strict';

  var computed = Ember['default'].computed;
  var htmlSafe = Ember['default'].String.htmlSafe;

  exports['default'] = Ember['default'].Component.extend({
    layout: layout['default'],
    tagName: '',

    // CPs
    triggerMultipleInputStyle: computed('searchText.length', 'selection.length', function () {
      if (this.get('selection.length') === 0) {
        return htmlSafe('width: 100%;');
      } else {
        return htmlSafe('width: ' + ((this.get('searchText.length') || 0) * 0.5 + 2) + 'em');
      }
    }),

    maybePlaceholder: computed('placeholder', 'selection.length', function () {
      return this.get('selection.length') === 0 ? this.get('placeholder') || '' : '';
    }),

    actions: {
      search: function search(term, e) {
        var _get = this.get('select.actions');

        var search = _get.search;
        var open = _get.open;

        search(term, e);
        open(e);
      }
    }
  });

});