define('liquid-fire/index', ['exports', 'liquid-fire/transition-map', 'liquid-fire/animate', 'liquid-fire/promise', 'liquid-fire/mutation-observer', 'liquid-fire/version-warnings', 'liquid-fire/velocity-ext'], function (exports, TransitionMap, animate, Promise, MutationObserver, versionWarnings) {

  'use strict';

  versionWarnings['default']({
    minEmberVersion: [1, 11],
    minVelocityVersion: [0, 11, 8]
  });

  exports.TransitionMap = TransitionMap['default'];
  exports.animate = animate.animate;
  exports.stop = animate.stop;
  exports.isAnimating = animate.isAnimating;
  exports.timeSpent = animate.timeSpent;
  exports.timeRemaining = animate.timeRemaining;
  exports.finish = animate.finish;
  exports.Promise = Promise['default'];
  exports.MutationObserver = MutationObserver['default'];

});